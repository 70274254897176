.SignUpWerknemer {
    display: block;
    grid-column-gap: 40px;
    grid-template-columns: 1fr;
}

.SignUpForm {
  display: block!important;
}

.SignUpForm input {
  margin-bottom: 20px;
}

.SignUpWerknemerForm   {
  display: block;
  background-color: #f7f7f7;
  padding:40px;
  width: 40%;
}

select {
    margin-bottom: 20px;
}

.no-suggestions {
  color: #f00;
  padding: 0.5rem;
  margin-bottom: 20px;
}

.suggestions {
  border: 1px solid #999;
  border-top-width: 0;
  list-style: none;
  margin-top: -20px;
  max-height: 143px;
  overflow-y: auto;
  padding-left: 0;
  width: calc(300px + 1rem);
}

.suggestions li {
  padding: 0.5rem;
}

.suggestion-active,
.suggestions li:hover {
  background-color: #2795c8;
  color: #ffffff;
  cursor: pointer;
  font-weight: 700;
}

.suggestions li:not(:last-of-type) {
  border-bottom: 1px solid #999;
}

span.Toelichting-werkgever-keuze {
    font-size: 14px;
    display: block;
    margin-bottom: 10px;
}

/* input.Autosuggestion {
    margin-bottom: 0px!important;
} */
