.Wachtwoord-formulier input {
  margin-bottom: 20px;
}

.Wachtwoord-formulier {
  margin-bottom: 10px;
  display: inline-block;
  /* background-color: #ffffff; */
  /* padding: 20px; */
  /* width: 40%; */
}

.Wachtwoord-formulier label {
  margin-bottom: 20px;
}
