.SignUpForm {
    display: block;
    grid-column-gap: 40px;
    grid-template-columns: 1fr;
}

.SignUpForm input {
  margin-bottom: 20px;
}

.SignUpDealerForm   {
  background-color: #f7f7f7;
  padding:40px;
  width: 40%;
}
