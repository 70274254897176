body {
    background-color: #ebebeb;
    /* height: 100vh; */
}

.Page {
  /* height: 100%; */
  padding: 3% 10% 3% 10%;
  padding-top: 120px;
}

a {
  color:#1e396d;
  font-weight: 700;
  text-decoration: none;
}

a:hover {
  color:#0d7fc3;
}

h1, h2, h3, h4, p, li, label  {
  font-family: 'Poppins', sans-serif;
}

p {
  font-size:17px;
  line-height: 1.75;
}

span {
  font-size:17px;
  line-height: 1.75;
}

td {
  font-size:17px;
  line-height: 1.75;
  vertical-align: top;
}


.Tabel-gegevens {
  display: block;
}

@media(max-width:950px) {
  .Tabel-gegevens tr {
    display: grid;
    margin-bottom: 5px;
}
}

.Tabel-full-width {
  width:100%;
  /* table-layout: fixed; */
  overflow-wrap: break-word;
}

.Tabel-full-width tr:nth-child(even) {
  background-color: #f9f9f9;
}

.Tabel-full-width tr:hover {
    background-color: #f2f2f2;
}

.Tabel-full-width td {
  line-height: 40px;
}


table td {
  padding: 0px 20px 0px 0px;
}

h1 {
  font-size: 34px;
  font-weight: 400;
  color: #1f396d;
}

h2 {
  font-size: 26px;
  font-weight: 400;
  color: #1f396d;
  margin-top: 0px;
}

h3 {
  font-size: 20px;
  font-weight: 400;
  color: #1f396d;
  margin-top: 0px;
}

input[type="text"],
input[type="password"] {
    padding: 10px 20px;
    margin-right: 10px;
    width:90%;
    font-size:17px;
    margin-bottom:20px;
}

input:not([type='submit']):not([type='button']):not([type='number']):not([type='checkbox']):not([type='radio']):focus {
    border-color: #95c11f;
}

textarea {
  padding: 10px;
  font-family: 'Poppins', sans-serif;
  font-size: 17px;
}

select {
  padding: 10px;
  width: 100%;
}

option {
  font-size: 17px;
}

label {
  font-size: 17px;
  font-weight: 700;
  margin-bottom:5px;
  margin-top:5px;
  color: #1f396d;
}

button {
  display: inline-block;
  color: #ffffff !important;
  background-color: #95c11f;
  border-color: #95c11f;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 600;
  padding: 10px 20px 10px 20px !important;
  font-style: normal !important;
  text-align: center;
  vertical-align: middle;
  margin-top: 1px;
  margin-bottom: 1px;
  cursor: pointer;
  background-image: none;
  border-style: solid;
  border-radius: 2px;
  outline: none;
  white-space: pre-wrap;
  -webkit-text-stroke: 0px;
  transition: color 200ms ease-in-out, background-color 200ms ease-in-out, border-color 200ms ease-in-out;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

button:hover {
  background-color: #749912;
  border-color: #749912;
}

button:disabled {
    background-color: #dedede;
    color: #a2a2a2!important;
    cursor: not-allowed;
    border: none;
}

button.red {
    background-color: red;
    border-color: red;
}

button.red:hover {
    background-color: #d80808;
    border-color: #d80808;
}

.Terug-naar-overzicht {
  padding-bottom: 20px;
}

span.Toelichting {
  font-size: 14px;
  display: block;
  margin-bottom: 10px;
}

.Button-text {
    margin-bottom: 0px;
}

@media(max-width:569px) {
  table {
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
  }
}

button.signOutButton {
    margin-top: 12px;
    margin-right: 10px;
    margin-left: 10px;
}

/*AG GRID*/
.ag-cell {
    font-family: poppins;
    font-size: 16px;
}

span.ag-header-cell-text {
    font-family: poppins;
}

/*geen border om tabel*/
.ag-root-wrapper.ag-layout-normal.ag-ltr {
    border: none;
}

/*geen border onder th*/
.ag-header.ag-focus-managed.ag-pivot-off {
    border-bottom: none;
}
