.Overicht-bedrijven {
  margin-bottom: 10px;
  /* padding: 40px; */
}

.Company {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-column-gap: 40px;
  grid-row-gap: 40px;
}

.Companies {
  background-color: #ffffff;
  padding: 40px;
  overflow-x: auto;
  position: relative;
}

.Company-info-block {
  background-color: #ffffff;
  padding: 40px;
}

.Company-info {
  display: grid;
}

.Company-werknemers {
  background-color: #ffffff;
  padding: 40px;
}

.Werknemer-naam {
  margin-right: 10px;
  font-weight: 600;
}

.Werknemer-email {
  margin-right: 10px;
}

.Upload-facturen {
  margin-bottom: 20px;
}

.Nieuw-bedrijf-aanmaken {
  margin-bottom: 20px;
  position: absolute;
  right: 0;
  top: 0;
  padding: 40px;
}
