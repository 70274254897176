.Werknemer-detail-overzicht {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(569px, 1fr));
  grid-column-gap: 40px;
  grid-row-gap: 40px;
}

@media(max-width:569px) {
  .Werknemer-detail-overzicht {
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  }

  .Werknemer-detail-gegevens {
    padding: 20px!important;
    overflow-x:auto;
  }

  .Werknemer-buttons {
      grid-template-columns: repeat(auto-fit, minmax(100%, 1fr))!important;
  }

  .Service-pagina {
      grid-template-columns: repeat(auto-fit, minmax(100%, 1fr))!important;
      /* grid-row-gap: 40px; */
  }

  .Handleidingen-blok {
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr))!important;
    grid-row-gap: 20px!important;
  }

  .Meldingen-buttons {
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr))!important;
  }

  .Melding-button {
    height: 20px!important;
  }

  .Werknemer-button {
    height: 30px!important;
  }
}

.Werknemer-detail-gegevens {
  background-color: #ffffff;
  padding: 40px;
}

.Werknemer-wijzig-gegevens {
  display: flex;
  justify-content: flex-end;
}

.Werknemer-buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 40px;
    grid-row-gap:40px;
}

.Meldingen-buttons {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 40px;
  grid-row-gap:40px;
}

.Werknemer-button {
  background-color: #2895c7;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items:  center;
  height: 200px;
  padding: 40px;
  text-align: center;
  cursor: pointer;
}

.Melding-button {
  background-color: #2895c7;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items:  center;
  height: 200px;
  padding: 40px;
  text-align: center;
  cursor: pointer;
}

.Werknemer-button:active {
  background-color: #000000;
}

.Werknemer-button:hover,
.Melding-button:hover {
    background-color: #2484b1;
}

.Werknemer-button h3,
.Melding-button h3 {
    color: #fff;
    margin-bottom: 0px;
}

.Service-pagina {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 40px;
}

.Service-pagina-inleiding {
  background-color: #ffffff;
  padding:40px;
}

.Gegevens-tegenpartij {
    margin-top: 20px;
}

textarea {
    height: 130px;
    width: 80%;
    margin-bottom: 20px;
}

.Betrokken-bij-ongeval {
  margin-top: 20px;
  margin-bottom: 20px;
}

.Upload-schadeformulier {
  margin-top: 20px;
}

.Handleidingen {
  background-color: #ffffff;
  padding: 40px;
    /* display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 40px;
    margin-bottom: 80px; */
}

.Handleidingen-blok {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 10px;
}

.Handleiding {
  /* background-color: #ffffff;
  text-align: center;
  padding: 20px; */
}

.Handleiding h3 {
  margin-bottom: 0px;
  margin-top: 10px;
  /* text-align: center; */
}

.Handleiding a {
  /* margin-top: 30px; */
}

.Melding-maken {
  margin-top:40px;
}

.Schadeformulier {
    display: grid;
    background-color: #f7f7f7;
    width: 50%;
    padding: 40px;
}

.Verzend-knop {
  margin-top:20px;
}

@media(max-width:569px) {
  .Schadeformulier {
    width: auto;
  }
}
