.Non-active-objects {
  background-color: #ffffff;
  padding: 40px;
  overflow-x: auto; /*zorgt voor scrollen tabel op mobiel*/
  margin-bottom: 40px;
}

.Active-objects {
  background-color: #ffffff;
  padding: 40px;
  margin-top:40px;
  overflow-x: auto; /*zorgt voor scrollen tabel op mobiel*/
}

.Object {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 40px;
  position: relative;
}

.Objects {
  position: relative;
}

.Poolobject-aanmaken-link {
  position: absolute;
  right: 0;
  top: -40px;
}

.Object-details {
  background-color: #ffffff;
  padding: 40px;
}

.Object-insurance {
  background-color: #ffffff;
  padding: 40px;
}

.Nieuw-object {
  display: flex;
  justify-content: flex-end;
}

.CreateObjectForm {
  display: grid;
  background-color: #f7f7f7;
  padding:40px;
  width: 40%;
}

.Nieuw-verzekering {
  display: flex;
  justify-content: flex-end;
}

.Terug-knop {
  margin-bottom: 20px;
}

.Dealer-informeer-balk {
  height: 60px;
  padding: 20px;
  margin-top: 20px;
  background-color: #ffffff;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 40px;
  position: relative;
}

.Dealer-informeer-balk-rood {
  height: 60px;
  padding: 20px;
  margin-top: 20px;
  background-color: red;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 40px;
  position: relative;
}

.Dealer-informeer-tekst {
  position: absolute;
  left: 40px;
}

.Dealer-informeer-tekst-rood {
  position: absolute;
  left: 40px;
  color: white;
  padding: 20px;
  top: -15px;
}

.Object-insurance-link a {
    color: red;
}

.Object-insurance-link-orange a {
    color: orange;
}

.Object-detail-tabel {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 40px;
}

.Object-detail-tabel-edit {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 40px;
}

.Check-ok {
  color:#2a8230;
  font-weight: 700;
  display: flex;
  justify-content: flex-start;
}

.Check-not-ok {
  color:red;
  font-weight: 700;
  display: flex;
  justify-content: flex-start;
}

.Check-not-ok-orange {
  color:orange;
  font-weight: 700;
  display: flex;
  justify-content: flex-start;
}

.left {
    justify-content: flex-start;
}

.Object-meldingen {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  margin-top: 40px;
}

.Onderhoudsfacturen,
.Schademeldingen,
.Servicemeldingen {
  background-color: #ffffff;
  padding: 40px;
}

.Onderhouds-factuur {
  padding:5px 10px;
}

.Onderhoudsfactuur-uploaden {
  background-color: #ffffff;
  padding: 40px;
}

.Schademelding-detail {
  /* background-color: #ffffff;
  padding: 40px; */
}

.Service-aanvraag-beoordelen {
  background-color: #ffffff;
  padding: 40px;
}

.Schademelding-details {
  background-color: #ffffff;
  padding: 40px;
}

.H3-tabel {
  margin-top:20px;
}

.Schademelding-detail-tabel {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(569px, 1fr));
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  background-color: #ffffff;
  padding: 40px;
}

@media(max-width:569px) {
  .Object-detail-tabel {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr)) !important;
    grid-column-gap: 40px;
    grid-row-gap: 40px;
  }

  .Schademelding-detail-tabel {
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  }
}

.Knop-met-marge {
  margin-top: 20px;
  display: grid;
}

.Stappen {
  display: grid;
}
