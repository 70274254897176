.Overzicht-werknemers {
  /* display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 40px; */
}

.Goedgekeurde-werknemers {
    background-color: #ffffff;
    padding: 40px;
    overflow-x: auto; /*tabel scrollbaar maken op mobiel*/
}

.Goed-te-keuren-werknemers {
  background-color: #ffffff;
  padding: 40px;
  margin-bottom: 40px;
  overflow-x: auto;
}

.Werknemer-pagina-werkgever {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(569px, 1fr));
  grid-column-gap: 40px;
  grid-row-gap:40px;
}

.Werknemer-informatie {
  background-color:#ffffff;
  padding:40px;
  /* display: grid; */
}

.Leasebudget-instellen {
  background-color:#ffffff;
  padding:40px;
}

.Button-marge {
  margin-top:20px;
}

.Werkgever-buttons {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-column-gap: 40px;
  grid-row-gap:40px;
}

@media(max-width:569px) {
  .Werkgever-buttons {
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  }

  .Werknemer-pagina-werkgever {
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  }
}

.Deelnemers,
.Facturen {
  background-color: #2895c6;
  color: #ffffff;
  padding: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.Deelnemers:hover,
.Facturen:hover {
  background-color: #2484b1;
}


.Deelnemers h3,
.Facturen h3 {
    color: #fff;
    margin-bottom: 0px
}

.Object-tabellen {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 40px;
}

@media(max-width:569px) {
  .Object-tabellen {
    grid-template-columns: 1fr;
  }
}

.Overzicht-poolobjecten {
  background-color: #ffffff;
  padding: 40px;
  position: relative;
  overflow-x: auto;
}

.Facturen-werkgever {
    display: grid;
    grid-row-gap: 40px;
}

.Marge-top {
  margin-top:20px;
}

.Bijdrage-werkgever {
  margin-bottom: 10px;
}

.Bijdrage-werkgever-p {
    margin:0px;
}

/*extra marge boven input veld bij 'Werkgever betaalt gedeeltelijk'*/
.input-marge {
    margin-top: 20px;
}
