.SignUpForm {
    display: grid;
    grid-column-gap: 40px;
    grid-template-columns: 1fr;
}

.SignUpForm input {
  margin-bottom: 20px;
}

.SignUpWerkgeverForm   {
  background-color: #f7f7f7;
  padding:40px;
  width: 40%;
}

select {
    margin-bottom: 20px;
}
