.Account-formulieren {
  display: grid;
  grid-column-gap: 40px;
  /* grid-template-columns: 1fr 1fr */

}

.PasswordForgetForm {
  margin-bottom: 10px;
  background-color: #f7f7f7;
  padding: 40px;
  /* width: 45%; */

}

.PasswordChangeForm {
  /* margin-bottom: 10px; */
  /* background-color: #f7f7f7;
  padding: 40px; */
  /* width: 45%; */
}

@media(min-width:570px) {
  .PasswordChangeForm {
    display: grid;
    background-color: #f7f7f7;
    padding: 40px;
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
    width: 45%;
  }
}

@media(max-width:569px) {
  .PasswordChangeForm {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
    /* width: 80%; */
  }
}
