.Dealer-items {
  display: grid;
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  grid-template-columns: repeat(auto-fit, minmax(569px, 1fr));
}

.Dealer-knoppen {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-column-gap: 40px;
    grid-row-gap: 40px;

}

@media(max-width:569px) {
  .Dealer-items {
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  }

  .Werknemer-object-gegevens {
      grid-template-columns: repeat(auto-fit, minmax(100%, 1fr))!important;
  }

}

.Search-sqootId {
  background-color: #ffffff;
  padding:40px
}

.Dealer-klanten {
  background-color: #ffffff;
  padding:40px;
  overflow-x: auto;
}

.Dealer-klanten-klantgegevens {
    display: inline-grid;
}

.Dealer-klanten-klantgegevens span {
  margin-right: 10px;
}

.Klantgegevens {
    display: grid;
}

.Klantgegevens span{
    margin-bottom: 5px;
}

.Upload-factuur {
  display: block;
}

.Klantgegevens-block {
  margin-top: 20px;
}

.Upload-factuur {
  margin-top: 20px;
}

th {
  text-align: left;
  font-size: 17px;
  padding: 0px 10px 10px 0px;
}

.Ophalen-button {
  font-size:12px;
}

.Werknemer-details-dealerpagina {
  display: grid;
  grid-column-gap: 40px;
  grid-row-gap:40px;
}

.Werknemer-object-gegevens {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    background-color: #fff;
    padding: 40px;
    grid-row-gap: 40px;
}



.Test {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-column-gap: 40px;
  grid-row-gap: 40px;
}

.Accessoires-lijst {
  list-style-type: none;
  padding-left: 0px;
  margin-top:0px;
  margin-bottom: 0px;
}

.Rood {
  color: red;
}

.Service-aanvragen {
  background-color: #ffffff;
  padding: 40px;
  /* display: grid;
  grid-template-columns: minmax(100%, 1fr); */
}

@media(max-width:569px) {
  .Service-aanvragen {
    display: grid;
    grid-template-columns: minmax(100%, 1fr);
  }

  .Test {
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr))!important;
  }
}

.Service-aanvraag-formulier {
  display: inline-table;
}

.Service-aanvraag-formulier-blok {
  overflow-x: auto;
}

.Onderhoudsfacturen-lijst {
  background-color: #ffffff;
  padding: 40px;
  overflow-x: auto;
}

.Onderhoudsfactuur-uploaden {
  background-color: #ffffff;
  /* padding: 40px; */
}

.Onderhoudsblok {
  display: grid;
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  margin-top: 40px;
}

.Bijzonderheden {
  margin-top:20px;
}

.Servicebeurt-keuze {
  margin-bottom: 20px;
}

.Servicebeurt-keuzes {
  margin-top: 10px;
}
