.Overicht-dealers {
  margin-bottom: 10px;
  /* padding: 40px; */
  /* background-color: #ffffff; */
}

.Dealer {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-column-gap: 40px;
}

.Dealers {
  background-color: #ffffff;
  padding: 40px;
  overflow-x: auto;
  position: relative;
}

.Dealer-info-block {
  background-color: #ffffff;
  padding: 40px;
}

.Dealer-werknemers {
  background-color: #ffffff;
  padding: 40px;
}

.Dealer-werknemers span {
  margin-right: 10px;
}
