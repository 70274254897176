.SignInForm {
  margin-bottom: 10px;
  background-color: #f7f7f7;
  padding: 40px;
  width: 40%;
  margin: 0 auto;
}


@media(max-width:569px) {
  .SignInForm-fields {
    width: 90%;
  }

  .SignInForm {
    width: auto;
  }
}

.SignInForm input {
  margin-bottom: 20px;
}
