.Admin {
    padding: 3% 10%;
}

.Overicht-werknemers {
  margin-bottom: 10px;
  position: relative;
}

.Phases {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  height: 100%;
  min-height: 600px;
  /* margin: 0 30px; */
  height: 100%;
  background-color: #ffffff;
  padding: 40px;
}

.Phase {
  flex: 0 0 auto;
  width: 300px;
  margin-right: 20px;
  padding: 5px, 10px;
  background-color: #f7f7f7;
}

.Werknemer {
    display: grid;
    background-color: #ffffff;
    margin: 20px;
    padding: 10px 20px;
    box-shadow: 0px 0px 4px #c3c3c3;
}

.Phase-header {
  /* background-color: #ffffff; */
  padding: 5px 10px;
  text-transform: uppercase;
  text-align: center;
}

.Werknemer-naam a {
  text-decoration: none;
  color: #1f396d;
}

.Werknemer-naam-werkgever a {
  text-decoration: none;
  color: #1f396d;
  font-size: 15px;
  font-weight: 400;
}

.Werknemer-details {
  display: grid;
  grid-template-columns: 1fr 1fr;
  background-color: #ffffff;
  padding: 40px;
  /* grid-row-start: 1; */
  grid-column-gap: 40px;
}

.Einde-contract {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 40px;
}

.Einde-contract-column {
  background-color: #ffffff;
  padding: 40px;
}

.Werknemer-activiteiten {
  display: block;
  background-color:#ffffff;
  padding: 40px;
  /* grid-row-start: 1; */
  /* grid-row-end: 3; */
}

.Werknemer-extra {
    margin-top: 60px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.Activiteit {
  margin-bottom: 20px;
}

.Created-at {
  font-size:12px;
  font-weight: 600;
}

.Activiteiten-lijst {
  list-style-type: none;
  padding:0px;
}

.Werknemer-gegevens {
  display: grid;
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  grid-template-columns: 2fr 1fr;
}

.Werknemer-gegevens-edit {
  grid-template-columns: 1fr
}

.Create-new-object-link a {
  color:red;
}

.Werknemer-verstuur-nieuw-wachtwoord {
    background-color: #ffffff;
    padding: 40px;
}

.Wijzigen {
    position: absolute;
    right: 0;
    top: 0;
}

.Fiets-gestolen {
    position: absolute;
    right: 120px;
    top: 0;
}

.Contract-eindigen {
    position: absolute;
    right: 150px;
    top: 0;
}

.Tabel-rij-titel {
  padding-top: 30px;
}

.Contract-button {
  background-color: #2895c7;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items:  center;
  height: 100px;
  padding: 40px;
  text-align: center;
  cursor: pointer;
}

.Contract-button h3 {
    color: #ffffff;
}

.Contract-button:hover,
.Contract-button:hover {
  background-color: #2484b1;
}
